var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
        [
          _vm.pos == "Entretien" && _vm.retrieveRH
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-1",
                              modifiers: { "accordion-1": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Ressources Humaines (Entretien)")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _c("span", [
                                _vm._v(
                                  "Nombre de Postes demandé: " +
                                    _vm._s(
                                      _vm.fields.filter(function(x) {
                                        return (
                                          x.poste.estimation_type ==
                                          "when_ordering"
                                        )
                                      }).length
                                    ) +
                                    " / Montant Vérification: " +
                                    _vm._s(
                                      new Intl.NumberFormat("de-DE", {
                                        style: "currency",
                                        currency: "DZD"
                                      }).format(_vm.RH_amount)
                                    ) +
                                    " "
                                )
                              ]),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c("div", { staticClass: "p-3" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "b-card",
                                  { attrs: { title: "Personnel Reçu" } },
                                  [
                                    _c(
                                      "table",
                                      { staticClass: "table mt-2" },
                                      [
                                        _c("thead", [
                                          _c("th", [_vm._v("Poste")]),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("à la demander")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Nombres demandé")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Nombres envoyé")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Unité")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Nombres Reçu")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Absences")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v("Les Absents")]
                                          )
                                        ]),
                                        _vm._l(_vm.fields, function(field) {
                                          return _c(
                                            "tr",
                                            { key: field.poste.id },
                                            [
                                              _c("th", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      field.poste.job_title
                                                        .designation
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              field.poste.estimation_type ==
                                              "when_ordering"
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-success text-center"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-check-circle text-success"
                                                      })
                                                    ]
                                                  )
                                                : _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-center"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-times-circle text-danger "
                                                      })
                                                    ]
                                                  ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(field.qte) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(field.prestQte) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        field.uom.designation
                                                      )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-right",
                                                  staticStyle: { width: "10%" }
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: field.checkQte,
                                                        expression:
                                                          "field.checkQte"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-invalid":
                                                        field.checkQte >
                                                        field.qte
                                                    },
                                                    attrs: {
                                                      type: "number",
                                                      disabled:
                                                        _vm.estimated == 1,
                                                      min: 0,
                                                      max: "" + field.qte
                                                    },
                                                    domProps: {
                                                      value: field.checkQte
                                                    },
                                                    on: {
                                                      keypress: function(
                                                        $event
                                                      ) {
                                                        return _vm.isNumber(
                                                          $event
                                                        )
                                                      },
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            field,
                                                            "checkQte",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.getAmount()
                                                        }
                                                      ]
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center text-danger"
                                                },
                                                [
                                                  _c(
                                                    "b-badge",
                                                    {
                                                      attrs: {
                                                        variant: "danger"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          field.qte -
                                                            parseInt(
                                                              field.checkQte
                                                                ? field.checkQte
                                                                : 0
                                                            ) >=
                                                            0
                                                            ? field.qte -
                                                                parseInt(
                                                                  field.checkQte
                                                                    ? field.checkQte
                                                                    : 0
                                                                )
                                                            : 0
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: { width: "20%" }
                                                },
                                                [
                                                  _c("multiselect", {
                                                    class: {
                                                      disabledMS:
                                                        field.qte -
                                                          parseInt(
                                                            field.checkQte
                                                              ? field.checkQte
                                                              : 0
                                                          ) ==
                                                          0 ||
                                                        _vm.estimated == 1
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        field.qte -
                                                          parseInt(
                                                            field.checkQte
                                                              ? field.checkQte
                                                              : 0
                                                          ) ==
                                                          0 ||
                                                        _vm.estimated == 1,
                                                      "deselect-label": "",
                                                      "select-label": "",
                                                      multiple: true,
                                                      "track-by": "id",
                                                      label: "first_name",
                                                      placeholder: "",
                                                      options: _vm.employes.filter(
                                                        function(x) {
                                                          return (
                                                            x.post ==
                                                            field.poste
                                                              .job_title.uuid
                                                          )
                                                        }
                                                      ),
                                                      "custom-label":
                                                        _vm.customLabel,
                                                      searchable: true,
                                                      "allow-empty": true,
                                                      max:
                                                        field.qte -
                                                        parseInt(
                                                          field.checkQte
                                                            ? field.checkQte
                                                            : 0
                                                        )
                                                    },
                                                    model: {
                                                      value: field.absences,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          field,
                                                          "absences",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "field.absences"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Entretien" && _vm.retrieveEQP == 1
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-2",
                              modifiers: { "accordion-2": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Ressources Materiels (Entretien)")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _c("span", [
                                _vm._v(
                                  "Montant : " +
                                    _vm._s(
                                      new Intl.NumberFormat("de-DE", {
                                        style: "currency",
                                        currency: "DZD"
                                      }).format(_vm.EQP_amount)
                                    )
                                )
                              ]),
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-2",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "b-card",
                                { attrs: { title: "Materiels Reçu" } },
                                [
                                  _c(
                                    "table",
                                    { staticClass: "table mt-2" },
                                    [
                                      _c("thead", [
                                        _c("th", [_vm._v("Poste")]),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("à la demander")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Quantité Demandé")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Unité")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Quantité Envoyée")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Quantité Reçu")]
                                        ),
                                        _c(
                                          "th",
                                          { staticClass: "text-center" },
                                          [_vm._v("Motif différence")]
                                        )
                                      ]),
                                      _vm._l(_vm.selectedMaterials, function(
                                        equipment
                                      ) {
                                        return _c(
                                          "tr",
                                          { key: equipment.equipment.id },
                                          [
                                            _c("th", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    equipment.equipment.name
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            equipment.equipment
                                              .estimation_type ==
                                            "when_ordering"
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-success text-center"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-check-circle text-success"
                                                    })
                                                  ]
                                                )
                                              : _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center"
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-times-circle text-danger "
                                                    })
                                                  ]
                                                ),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(equipment.qte) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      equipment.uom.designation
                                                    )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(equipment.prestQte)
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass: "text-right",
                                                staticStyle: { width: "20%" }
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: equipment.checkQte,
                                                      expression:
                                                        "equipment.checkQte"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "number",
                                                    max:
                                                      equipment.equipment
                                                        .estimated_qty,
                                                    min: "0"
                                                  },
                                                  domProps: {
                                                    value: equipment.checkQte
                                                  },
                                                  on: {
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          equipment,
                                                          "checkQte",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function($event) {
                                                        return _vm.getAmount()
                                                      }
                                                    ],
                                                    keypress: function($event) {
                                                      return _vm.isNumber(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass: "text-center",
                                                staticStyle: { width: "20%" }
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: equipment.motif,
                                                      expression:
                                                        "equipment.motif"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    disabled:
                                                      equipment.qte -
                                                        parseInt(
                                                          equipment.checkQte
                                                            ? equipment.checkQte
                                                            : 0
                                                        ) ==
                                                      0
                                                  },
                                                  domProps: {
                                                    value: equipment.motif
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        equipment,
                                                        "motif",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Fournitures des produits phytosanitaires"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Produits")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c(
                          "table",
                          { staticClass: "table mt-2" },
                          [
                            _c("thead", [
                              _c("th", [_vm._v("Produit")]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Délai")
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Quantité demandé")
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Quantité envoyé")
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Quantité Reçu")
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Motif différence")
                              ])
                            ]),
                            _vm._l(_vm.selectedProducts, function(product) {
                              return _c("tr", { key: product.product.id }, [
                                _c("th", [
                                  _vm._v(
                                    " " +
                                      _vm._s(product.product.designation) +
                                      " "
                                  )
                                ]),
                                _c("th", [
                                  _vm._v(" " + _vm._s(_vm.period) + " ")
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(" " + _vm._s(product.qte) + " ")
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(" " + _vm._s(product.prestQte) + " ")
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { width: "33%" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: product.checkQte,
                                          expression: "product.checkQte"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number", min: "0" },
                                      domProps: { value: product.checkQte },
                                      on: {
                                        input: [
                                          function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              product,
                                              "checkQte",
                                              $event.target.value
                                            )
                                          },
                                          function($event) {
                                            return _vm.getAmount()
                                          }
                                        ],
                                        keypress: function($event) {
                                          return _vm.isNumber($event)
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { width: "20%" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: product.motif,
                                          expression: "product.motif"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          product.qte -
                                            parseInt(
                                              product.checkQte
                                                ? product.checkQte
                                                : 0
                                            ) ==
                                          0
                                      },
                                      domProps: { value: product.motif },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            product,
                                            "motif",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            })
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Matériel roulant"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Matériel roulant")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c(
                          "table",
                          { staticClass: "table mt-2" },
                          [
                            _c("thead", [
                              _c("th", [_vm._v("Produit")]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("à la demander")
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Quantité demandé")
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Quantité envoyé")
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Quantité Reçu")
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Motif différence")
                              ])
                            ]),
                            _vm._l(_vm.selectedMTR, function(material) {
                              return _c("tr", { key: material.id }, [
                                _c("th", [
                                  _vm._v(
                                    " " + _vm._s(material.equipment.name) + " "
                                  )
                                ]),
                                material.equipments.estimation_type ==
                                "when_ordering"
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "text-success text-center"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-check-circle text-success"
                                        })
                                      ]
                                    )
                                  : _c("td", { staticClass: "text-center" }, [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-times-circle text-danger "
                                      })
                                    ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(" " + _vm._s(material.qte) + " ")
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(" " + _vm._s(material.prestQte) + " ")
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { width: "20%" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: material.checkQte,
                                          expression: "material.checkQte"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number", min: "0" },
                                      domProps: { value: material.checkQte },
                                      on: {
                                        input: [
                                          function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              material,
                                              "checkQte",
                                              $event.target.value
                                            )
                                          },
                                          function($event) {
                                            return _vm.getAmount()
                                          }
                                        ],
                                        keypress: function($event) {
                                          return _vm.isNumber($event)
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { width: "33%" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: material.motif,
                                          expression: "material.motif"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          material.qte -
                                            parseInt(
                                              material.checkQte
                                                ? material.checkQte
                                                : 0
                                            ) ==
                                          0
                                      },
                                      domProps: { value: material.motif },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            material,
                                            "motif",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            })
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.pos == "Réalisation"
            ? _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(" Réalisation")
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c(
                          "table",
                          { staticClass: "table mt-2" },
                          [
                            _c("thead", [
                              _c("th", [_vm._v("Réalisation")]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Délai")
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Quantité demandé")
                              ]),
                              _c("th", { staticClass: "text-center" }, [
                                _vm._v("Quantité envoyé")
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Quantité Reçu")
                              ]),
                              _c("th", { staticClass: "text-right" }, [
                                _vm._v("Motif différence")
                              ])
                            ]),
                            _vm._l(_vm.selectedReal, function(material) {
                              return _c("tr", { key: material.id }, [
                                _c("th", [
                                  _vm._v(
                                    " " + _vm._s(material.realize.name) + " "
                                  )
                                ]),
                                _c("th", [
                                  _vm._v(" " + _vm._s(_vm.period) + " ")
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(" " + _vm._s(material.qte) + " ")
                                ]),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(" " + _vm._s(material.prestQte) + " ")
                                ]),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    staticStyle: { width: "20%" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: material.checkQte,
                                          expression: "material.checkQte"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "number", min: "0" },
                                      domProps: { value: material.checkQte },
                                      on: {
                                        input: [
                                          function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              material,
                                              "checkQte",
                                              $event.target.value
                                            )
                                          },
                                          function($event) {
                                            return _vm.getAmount()
                                          }
                                        ],
                                        keypress: function($event) {
                                          return _vm.isNumber($event)
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { width: "33%" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: material.motif,
                                          expression: "material.motif"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          material.qte -
                                            parseInt(
                                              material.checkQte
                                                ? material.checkQte
                                                : 0
                                            ) ==
                                          0
                                      },
                                      domProps: { value: material.motif },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            material,
                                            "motif",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            })
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }