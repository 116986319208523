<script>
  import Multiselect from 'vue-multiselect'

const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;
export default {
    components:{Multiselect},
    props:['service','ODS','send','pos','estimated','disable','planB','emp','period'],
    data(){
        return {
            active:false,
            addPersonel:false,
            fields:[{poste:{poste:"Superviseur",toAsk:false,qte:1},Qte:2,checkQte:2,uom:{designation:'Personne'}}],
            selectedMaterials:[{equipment:"",qte:0,amount:0}],
            selectedProducts:[{equipment:"",qte:0,amount:0}],
            selectedReal:[{realize:"",qte:0,amount:0}],
            selectedMTR:[{equipment:"",equipments:{},qte:0,amount:0}],
            selectedAbs:{},
            RH:[],
            postes:[],
            retrieveRH:false,
            retrieveEQP:false,
            EQP_amount:0,
            RH_amount:0,
            employes:[]
        }
    },
    mounted(){
        
        this.employes = this.emp;
            switch (this.pos) {
                case 'Entretien':
                    var keys = Object.keys(this.service[this.pos]);
                    keys.forEach(x => {
                        switch (x) {
                            case 'human':
                                this.fields = [];
                                this.retrieveRH = true;
                                this.service[this.pos][x].forEach(element => {
                                    this.fields.push({
                                        id:element.id,
                                        poste:element.service,
                                        qte:element.quantity,
                                        amount:element.amount,
                                        uom:element.uom,
                                        prestQte:element.quantity_prest,
                                        checkQte:element.quantity_check > 0 ? element.quantity_check : element.quantity,
                                        checkAmount:0,
                                        absences:element.absences ? element.absences.map(x => x.employee) : []
                                    })
                                    // this.PostesList.push(element.service)
                                })
                                this.getAmount();
                                this.emitData();
                                break;
                            case 'equipment':
                                this.retrieveEQP = true;
                                    this.selectedMaterials = [];
                                    this.service[this.pos][x].forEach(element => {
                                        this.selectedMaterials.push({
                                            id:element.id,
                                            equipment:element.service,
                                            qte:element.quantity,
                                            amount:element.amount,
                                            uom:element.uom,
                                            prestQte:element.quantity_prest,
                                            checkQte:element.quantity_check > 0 ? element.quantity_check : element.quantity,
                                            checkAmount:0,
                                            motif:element.reason != null ? element.reason : ""
                                        })
                                    })
                                    this.getAmount();
                                    this.emitData();
                                break;
                        
                            default:
                                break;
                        }
                    })
                    
                    break;
                case 'Fournitures des produits phytosanitaires':
                    this.selectedProducts = [];
                    this.service[this.pos].forEach(element => {
                        this.selectedProducts.push({
                            id:element.id,
                            product:element.product,
                            qte:element.quantity,
                            amount:element.amount,
                            prestQte:element.quantity_prest,
                            checkQte:element.quantity_check > 0 ? element.quantity_check : element.quantity,
                            checkAmount:0,
                            motif:element.reason != null ? element.reason : ""
                        })
                        // this.ProductsList.push(element.product)
                    })
                    this.getAmount();
                    this.emitData();
                    break;

                case 'Matériel roulant':
                    this.selectedMTR = [];
                    this.service[this.pos].forEach((element,i) => {
                        this.selectedMTR.push({
                            id:element.id,
                            equipment:element.equipment,
                            equipments:element.service,
                            qte:element.quantity,
                            amount:element.amount,
                            prestQte:element.quantity_prest,
                            checkQte:element.quantity_check > 0 ? element.quantity_check : element.quantity,
                            checkAmount:0,
                            motif:element.reason != null ? element.reason : ""
                        })
                    })
                    console.log(this.selectedMTR);
                    this.getAmount();
                    this.emitData();
                    break;

                case 'Réalisation':
                    this.selectedReal = [];
                    this.service[this.pos].forEach((element,i) => {
                        this.selectedReal.push({
                            id:element.id,
                            realize:element.service,
                            qte:element.quantity,
                            amount:element.amount,
                            prestQte:element.quantity_prest,
                            checkQte:element.quantity_check > 0 ? element.quantity_check : element.quantity,
                            checkAmount:0,
                            motif:element.reason != null ? element.reason : ""
                        })
                    })
                    console.log(this.selectedMTR);
                    this.getAmount();
                    this.emitData();
                    break;
            
                default:
                    break;
            }
        // }
        // else {
        //     this.PostesList = this.service[this.pos];
        //     this.ProductsList = this.service[this.pos];
        // }
    },
    methods:{
        getAmount(){
            var total = 0;
            if(this.pos == 'Entretien'){
                this.RH_amount=0;
                this.EQP_amount=0;
                 this.fields.forEach(element => {
                     if(parseInt(element.checkQte) > element.qte ) element.checkQte = element.qte;
                     element.checkAmount = parseInt(element.poste.unit_price) * (parseInt(element.checkQte) > element.qte ? element.qte : element.checkQte);
                     this.RH_amount += (element.checkAmount ? parseInt(element.checkAmount) : 0);
                 })

                 this.selectedMaterials.forEach(element => {
                     if(parseInt(element.checkQte) > element.qte ) element.checkQte = element.qte;
                     element.checkAmount = parseInt(element.equipment.unit_price) * (parseInt(element.checkQte) > element.qte ? element.qte : element.checkQte);
                     this.EQP_amount += (element.checkAmount ? parseInt(element.checkAmount) : 0);
                 })
                this.emitData();
            }
            if(this.pos == 'Fournitures des produits phytosanitaires'){
                 this.selectedProducts.forEach(element => {
                     if(parseInt(element.checkQte) > element.qte ) element.checkQte = element.qte;
                     element.checkAmount = parseInt(element.product.unit_price) * (parseInt(element.checkQte) > element.qte ? element.qte : element.checkQte);
                     total += parseInt(element.checkAmount);
                 })
                this.emitData();
                 
            }
            if(this.pos == 'Matériel roulant'){
                 this.selectedMTR.forEach(element => {
                     if(parseInt(element.checkQte) > element.qte ) element.checkQte = element.qte;
                     element.checkAmount = parseInt(element.equipments.unit_price) * (parseInt(element.checkQte) > element.qte ? element.qte : element.checkQte);
                     total += parseInt(element.checkAmount);
                 })
                this.emitData();
                 
            }
            if(this.pos == 'Réalisation'){
                 this.selectedReal.forEach(element => {
                     if(parseInt(element.checkQte) > element.qte ) element.checkQte = element.qte;
                     element.checkAmount = parseInt(element.realize.unit_price) * (parseInt(element.checkQte) > element.qte ? element.qte : element.checkQte);
                     total += parseInt(element.checkAmount);
                 })
                this.emitData();
                 
            }

            return total;
        },
        emitData(){
            switch (this.pos) {
                case 'Entretien':
                    this.$emit('getvalues',{human:this.fields,equipment:this.selectedMaterials})
                    break;
                case 'Fournitures des produits phytosanitaires':
                    this.$emit('getvalues',this.selectedProducts)
                    break;
                case 'Matériel roulant':
                    this.$emit('getvalues',this.selectedMTR)
                    break;
                case 'Réalisation':
                    this.$emit('getvalues',this.selectedReal)
                    break;
            
                default:
                    break;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                this.getAmount();
                this.emitData();
            }
        },
        customLabel({first_name,last_name}){
            return `${first_name} ${last_name}` 
        },
    }
    
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion mb-2" role="tablist">
                <b-card no-body class="mb-1"  v-if="pos == 'Entretien' && retrieveRH">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                        <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Ressources Humaines (Entretien)</div>
                        <div class="col text-right">
                            <span>Nombre de Postes demandé: {{fields.filter(x => (x.poste.estimation_type == 'when_ordering')).length}} / Montant Vérification: {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(RH_amount)}} </span>
                            <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                            <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                        </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="p-3">
                                <div class="row">
                                    <div class="col">
                                    <b-card title="Personnel Reçu">
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Poste</th>
                                                <th class="text-center">à la demander</th>
                                                <th class="text-center">Nombres demandé</th>
                                                <th class="text-center">Nombres envoyé</th>
                                                <th class="text-center">Unité</th>
                                                <th class="text-center">Nombres Reçu</th>
                                                <th class="text-center">Absences</th>
                                                <th class="text-center">Les Absents</th>
                                            </thead>

                                            <tr v-for="field in fields" :key="field.poste.id">
                                                <th> {{field.poste.job_title.designation}} </th>
                                                <!-- <td v-if="field.selectedPoste.toAsk" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td>
                                                <td v-else class="text-center"><i class="fas fa-times-circle text-danger "></i></td> -->
                                                <td v-if="field.poste.estimation_type == 'when_ordering'" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td>
                                                <td v-else class="text-center"><i class="fas fa-times-circle text-danger "></i></td>
                                                <td class="text-center"> {{field.qte}} </td>
                                                <td class="text-center"> {{field.prestQte}} </td>
                                                <td class="text-center"> {{field.uom.designation}}</td>
                                                <td class="text-right" style="width:10%"> <input type="number" :disabled="estimated==1" class="form-control" :min="0" :max="`${field.qte}`" :class="{'is-invalid':field.checkQte > field.qte}" v-model="field.checkQte" @keypress="isNumber($event)" @input="getAmount()"></td>
                                                <td class="text-center text-danger"> <b-badge variant="danger">{{ (field.qte - parseInt(field.checkQte ? field.checkQte : 0)) >= 0 ? field.qte - parseInt(field.checkQte ? field.checkQte : 0) : 0}}</b-badge>  </td>
                                                <td class="text-center"  style="width:20%">
                                                     <multiselect 
                                                        v-model="field.absences" 
                                                        :class="{'disabledMS':(field.qte - parseInt(field.checkQte ? field.checkQte : 0)) == 0 || estimated==1}" 
                                                        :disabled="(field.qte - parseInt(field.checkQte ? field.checkQte : 0)) == 0 || estimated==1"  
                                                        :deselect-label="''" :select-label="''"  :multiple="true"  track-by="id" label="first_name" placeholder="" 
                                                        :options="employes.filter(x=>(x.post==field.poste.job_title.uuid))" 
                                                        :custom-label="customLabel" 
                                                        :searchable="true" :allow-empty="true"
                                                        :max="field.qte - parseInt(field.checkQte ? field.checkQte : 0)"
                                                    >
                                                         <!-- <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.first_name }}</strong></template> -->
                                                    </multiselect> </td>
                                                <!-- <td class="text-center"  style="width:20%"> <input type="text" v-model="field.motif" class="form-control" :disabled="(field.qte - parseInt(field.checkQte ? field.checkQte : 0)) == 0 "> </td> -->
                                            </tr>
                                        </table>
                                        
                                    </b-card>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1"  v-if="pos == 'Entretien' && retrieveEQP==1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Ressources Materiels (Entretien)</div>
                            <div class="col text-right">
                                <span>Montant : {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(EQP_amount)}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="row">
                                
                                <div class="col">
                                    <b-card title="Materiels Reçu">
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Poste</th>
                                                <th class="text-center">à la demander</th>
                                                <th class="text-center">Quantité Demandé</th>
                                                <th class="text-center">Unité</th>
                                                <th class="text-center">Quantité Envoyée</th>
                                                <th class="text-center">Quantité Reçu</th>
                                                <th class="text-center">Motif différence</th>
                                            </thead>

                                            <tr v-for="equipment in selectedMaterials" :key="equipment.equipment.id">
                                                <th> {{equipment.equipment.name}} </th>
                                                <td v-if="equipment.equipment.estimation_type == 'when_ordering'" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td>
                                                <td v-else class="text-center"><i class="fas fa-times-circle text-danger "></i></td>
                                                <td class="text-center"> {{equipment.qte}} </td>
                                                <td class="text-center"> {{equipment.uom.designation}}</td>
                                                <td class="text-center"> {{equipment.prestQte}}</td>
                                                <td class="text-right" style="width:20%"> <input type="number"  :max="equipment.equipment.estimated_qty" class="form-control" min="0" v-model="equipment.checkQte" @input="getAmount()" @keypress="isNumber($event)"></td>
                                                <td class="text-center"  style="width:20%"> <input type="text" v-model="equipment.motif" class="form-control" :disabled="(equipment.qte - parseInt(equipment.checkQte ? equipment.checkQte : 0)) == 0 "> </td>
                                            </tr>
                                        </table>
                                    </b-card>
                                </div> 
                            </div>
                                
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-if="pos == 'Fournitures des produits phytosanitaires'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Produits</div>
                            <div class="col text-right">
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <table class="table mt-2">
                                <thead>
                                    <th>Produit</th>
                                    <th class="text-center">Délai</th>
                                    <th class="text-center">Quantité demandé</th>
                                    <th class="text-center">Quantité envoyé</th>
                                    <th class="text-right">Quantité Reçu</th>
                                    <th class="text-right">Motif différence</th>
                                </thead>

                                <tr v-for="product in selectedProducts" :key="product.product.id">
                                    <th> {{product.product.designation}} </th>
                                    <th> {{period}} </th>
                                    <td class="text-center"> {{product.qte}} </td>
                                    <td class="text-center"> {{product.prestQte}} </td>
                                    <td class="text-right" style="width:33%"> <input type="number" class="form-control"  min="0" v-model="product.checkQte" @input="getAmount()" @keypress="isNumber($event)"></td>
                                    <td class="text-center"  style="width:20%"> <input type="text" v-model="product.motif" class="form-control" :disabled="(product.qte - parseInt(product.checkQte ? product.checkQte : 0)) == 0 "> </td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-if="pos == 'Matériel roulant'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Matériel roulant</div>
                            <div class="col text-right">
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <table class="table mt-2">
                                <thead>
                                    <th>Produit</th>
                                    <th class="text-center">à la demander</th>
                                    <th class="text-center">Quantité demandé</th>
                                    <th class="text-center">Quantité envoyé</th>
                                    <th class="text-right">Quantité Reçu</th>
                                    <th class="text-right">Motif différence</th>
                                </thead>

                                <tr v-for="material in selectedMTR" :key="material.id">
                                    <th> {{material.equipment.name}} </th>
                                    <td v-if="material.equipments.estimation_type == 'when_ordering'" class="text-success text-center"><i  class="fas fa-check-circle text-success"></i></td>
                                    <td v-else class="text-center"><i class="fas fa-times-circle text-danger "></i></td>
                                    <td class="text-center"> {{material.qte}} </td>
                                    <td class="text-center"> {{material.prestQte}} </td>
                                    <td class="text-right" style="width:20%"> <input type="number" class="form-control" min="0" v-model="material.checkQte" @input="getAmount()" @keypress="isNumber($event)"></td>
                                    <td class="text-center"  style="width:33%"> <input type="text" v-model="material.motif" class="form-control" :disabled="(material.qte - parseInt(material.checkQte ? material.checkQte : 0)) == 0 "> </td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-if="pos == 'Réalisation'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Réalisation</div>
                            <div class="col text-right">
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <table class="table mt-2">
                                <thead>
                                    <th>Réalisation</th>
                                    <th class="text-center">Délai</th>
                                    <th class="text-center">Quantité demandé</th>
                                    <th class="text-center">Quantité envoyé</th>
                                    <th class="text-right">Quantité Reçu</th>
                                    <th class="text-right">Motif différence</th>
                                </thead>

                                <tr v-for="material in selectedReal" :key="material.id">
                                    <th> {{material.realize.name}} </th>
                                    <th> {{period}} </th>
                                    <td class="text-center"> {{material.qte}} </td>
                                    <td class="text-center"> {{material.prestQte}} </td>
                                    <td class="text-right" style="width:20%"> <input type="number" class="form-control" min="0" v-model="material.checkQte" @input="getAmount()" @keypress="isNumber($event)"></td>
                                    <td class="text-center"  style="width:33%"> <input type="text" v-model="material.motif" class="form-control" :disabled="(material.qte - parseInt(material.checkQte ? material.checkQte : 0)) == 0 "> </td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>